@import url("https://fonts.cdnfonts.com/css/general-sans");
@font-face {
  font-family: "Naigheta";
  font-display: auto;
  src: url("../../font/nagietha/Nagitha.otf");
}
:root {
  --main-color: #034b2b;
  --secondary-color: #e6edea;
  --grey-color: #747474;
  --outline: #eceff1;
}

.font-500 {
  font-family: "GeneralSans-Medium", sans-serif !important;
}

.font-600 {
  font-family: "GeneralSans-Bold", sans-serif !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  font-family: "GeneralSans-Regular", sans-serif !important;
}

.navbar {
  background: transparent !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10001;
  transition: 0.3s ease-in-out;
  padding: 20px 0px;
}
.navbar.bg-white {
  background-color: #fff !important;
}
.navbar .logo {
  width: 200px;
}
.navbar.menuscroll {
  box-shadow: 5px 12px 20px rgba(138, 138, 138, 0.13);
  background-color: #fff !important;
  border-bottom: 1px solid var();
}
.navbar.menuscroll li .nav-link {
  color: var(--grey-color) !important;
}
.navbar.menuscroll li .nav-link.active {
  color: var(--main-color) !important;
  font-weight: 600;
}
.navbar li .nav-link {
  color: #fff !important;
  margin: 0px 12px;
}
.navbar li .nav-link.text-grey {
  color: var(--grey-color) !important;
}
.navbar li .nav-link.text-grey.active {
  color: var(--main-color) !important;
}
.navbar li .nav-link.active {
  font-weight: 600;
}

.title-section {
  font-size: 40px;
  font-weight: 600;
  color: var(--main-color);
}

.sub-title-section {
  font-size: 20px;
  color: var(--grey-color);
}

.btn-more {
  border-radius: 47px;
  padding: 12px 20px;
  font-size: 18px;
  font-weight: 500;
}
.btn-more.secondary {
  background-color: var(--secondary-color);
}
.btn-more.primary {
  background-color: var(--main-color);
  color: #fff;
}

.btn-green {
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  padding: 21px 20px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background-color: var(--main-color);
  color: #fff;
}
.btn-green:hover {
  background-color: var(--main-color);
  color: #fff;
}

.btn-checkout {
  padding: 12px 10px;
  color: #fff;
  font-size: 16px;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  min-width: 200px;
  margin-top: 10px;
}

.btn-orange {
  background-color: #fe5722;
}
.btn-orange:hover, .btn-orange:active, .btn-orange:focus {
  background-color: #d54517;
  color: white;
}

.btn-blue {
  background-color: #0072ff;
}
.btn-blue:hover, .btn-blue:active, .btn-blue:focus {
  background-color: #0c4a97;
  color: white;
}

.btn-light-green {
  background-color: #41b549;
}
.btn-light-green:hover, .btn-light-green:active, .btn-light-green:focus {
  background-color: #308f36;
  color: white;
}

textarea {
  height: 90px !important;
}

.form-control {
  height: 51px;
}

.item-product {
  text-align: left;
  margin: 0px 20px;
  overflow: hidden;
}
.item-product .review {
  color: var(--grey-color);
}
.item-product:hover .img-product {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
}
.item-product .card-img {
  width: 247px;
  height: 263px;
  overflow: hidden;
}
.item-product .card-img .img-product {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: transform 0.5s ease;
  -webkit-transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -moz-transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -ms-transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -o-transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
.item-product .title {
  margin-top: 10px;
  font-size: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 60px;
}
.item-product .type {
  font-size: 18px;
  margin-top: 5px;
  font-style: italic;
  color: var(--grey-color);
}
.item-product .price {
  font-size: 20px;
  margin-top: 20px;
}
.item-product .price .original {
  font-size: 12px;
  font-style: italic;
  text-decoration: line-through;
  display: inline-block;
  margin-right: 8px;
}
.item-product .price .discount {
  color: #fc3434;
  font-weight: 600;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  color: inherit;
}

.breadcrumb-item a {
  color: var(--grey-color);
}
.breadcrumb-item.active {
  color: var(--main-color);
  font-weight: 500;
}

.breadcrumb {
  margin-top: 10px;
}

footer {
  background-color: #e6edea;
  text-align: center;
  position: relative;
  padding: 40px 0px;
}
footer .logo-footer {
  width: 300px;
}
footer .img-footer-1 {
  left: 0;
  bottom: 0;
  width: 300px;
  position: absolute;
  mix-blend-mode: darken;
}
footer .img-footer-2 {
  right: 0;
  bottom: 0;
  width: 400px;
  position: absolute;
  mix-blend-mode: darken;
}
footer .nav-list .item-nav {
  margin: 0px 15px;
  color: var(--main-color);
}
footer .sosmed div {
  margin: 0px 15px;
}
footer .copyright {
  color: #bababb;
}

#post-page .img-blog {
  width: 60%;
  height: 400px;
  -o-object-fit: cover;
     object-fit: cover;
}
#post-page .title-blog {
  color: var(--main-color);
  font-size: 40px;
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: 500;
}

#contact .maps {
  margin-top: 0px;
}

.react-multiple-carousel__arrow--left {
  left: 0;
  background-color: var(--secondary-color);
}

.react-multiple-carousel__arrow::before {
  color: var(--main-color);
  font-weight: 600;
}

.react-multiple-carousel__arrow:hover {
  background-color: var(--secondary-color);
}

.react-multiple-carousel__arrow {
  background-color: var(--secondary-color);
}

.react-multiple-carousel__arrow--right {
  right: 0;
}

label > input[type=radio],
label > input[type=radio] {
  display: none !important;
}

label > input[type=radio] + *::before,
label > input[type=radio] + *::before {
  content: "" !important;
  display: inline-block !important;
  vertical-align: bottom !important;
  width: 1.1rem !important;
  height: 1.1rem !important;
  margin-right: 0.3rem !important;
  border-radius: 50% !important;
  border-style: solid;
  border-width: 0.1rem;
  border-color: #eceff1 !important;
}

label > input[type=radio]:checked + *,
label > input[type=radio]:checked + * {
  color: var(--primary) !important;
}

label > input[type=radio]:checked + *::before,
label > input[type=radio]:checked + *::before {
  background: radial-gradient(var(--main-color) 0%, var(--main-color) 40%, transparent 50%, transparent) !important;
  border-color: var(--main-color) !important;
}

.section-page {
  padding-top: 138px;
  padding-bottom: 100px;
  position: relative;
}
.section-page .img-right {
  width: 100%;
  height: 350px;
  -o-object-fit: cover;
     object-fit: cover;
}
.section-page .desc {
  text-align: left;
  color: var(--grey-color);
  text-align: justify;
}
.section-page .title-section {
  font-size: 40px;
  font-weight: 600;
  line-height: normal;
}
.section-page .ornament {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  z-index: 1;
}

#about-us-page .img-banner {
  width: 100%;
  margin: 40px 0px;
}
#about-us-page .title-section {
  font-family: "Naigheta", sans-serif !important;
  font-size: 120px;
  font-weight: 400;
}

.item-post {
  text-align: left;
  margin-bottom: 30px;
}
.item-post img,
.item-post .skeleton-image {
  width: 100%;
  height: 400px;
  -o-object-fit: cover;
     object-fit: cover;
}
.item-post .title {
  font-size: 20px;
  width: 85%;
}
.item-post .icon {
  width: 15%;
  text-align: right;
}

.input-group-text {
  background-color: transparent;
}

.accordion__button {
  background-color: transparent !important;
  font-weight: 600;
  position: relative;
}

.accordion__button:before {
  position: absolute;
  right: 20px;
  margin-right: 0;
}

.accordion__button {
  padding-left: 0 !important;
}

.btn-filter {
  border: 1px solid var(--outline);
  font-weight: 500;
  font-size: 12px;
  padding: 8px 13px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.text {
  font-size: 14px;
  color: var(--grey-color);
}

.modal-backdrop {
  opacity: 0.5;
  z-index: 10002;
}

.modal-content {
  overflow: hidden !important;
}

.modal {
  z-index: 10004;
}

.close-modal {
  position: absolute;
  right: 10px;
  top: 2%;
  cursor: pointer;
  z-index: 100;
}

.modal-title {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}

.modal-body {
  padding: 25px 25px !important;
  overflow: auto;
  height: 400px;
}

.modal-header .btn-close {
  position: absolute;
  right: 20px;
  top: 25px;
}

.item-post .title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 64px;
}

.video-responsive {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}
.video-responsive iframe {
  height: 680px;
}

.section-promo .content-wrapper {
  position: absolute;
  left: 50%;
  padding: 0px 50px;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.section-promo .content-wrapper .title-section {
  color: #fff;
}
.section-promo .content-wrapper .sub-title-section {
  color: #fff;
}
.section-promo .content-wrapper .btn-more {
  background-color: var(--main-color);
  color: #fff;
}
.section-promo .img-promo {
  width: 100%;
  height: 418px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0px 40px 0px 0px;
  -webkit-border-radius: 0px 40px 0px 0px;
  -moz-border-radius: 0px 40px 0px 0px;
  -ms-border-radius: 0px 40px 0px 0px;
  -o-border-radius: 0px 40px 0px 0px;
}

@media (max-width: 768px) {
  .section-promo.our-promo .img-promo {
    display: none;
  }
  .section-promo.our-promo .btn-more {
    background-color: transparent;
    color: var(--primary) !important;
    text-align: right;
    margin-top: 0px !important;
  }
  .section-promo.our-promo .btn-more svg {
    display: none;
  }
  .section-promo.our-promo .content-wrapper {
    position: unset;
    transform: unset;
    -webkit-transform: unset;
    -moz-transform: unset;
    -ms-transform: unset;
    -o-transform: unset;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .section-promo.our-promo .content-wrapper .title-section {
    color: var(--main-color);
    font-size: 20px;
    text-align: left !important;
  }
  .video-responsive iframe {
    height: 380px;
  }
  .section-page {
    padding-top: 100px;
  }
  .item-product .title {
    height: 48px;
    font-size: 16px;
  }
  .item-product .card-img .img-product {
    -o-object-fit: contain;
       object-fit: contain;
  }
  .item-product .card-img {
    width: 100%;
    height: 160px;
  }
  .item-product .price .original {
    width: 100%;
  }
  .item-product .price {
    font-size: 16px;
  }
  .btn-checkout {
    width: 100%;
    margin-bottom: 15px;
  }
  .navigation-menu {
    display: none;
  }
  .navigation-menu.expanded {
    display: flex;
    width: 100%;
  }
  .navbar li .nav-link {
    margin-left: 0px;
  }
  .title-section {
    font-size: 30px;
  }
  .sub-title-section {
    font-size: 18px;
  }
  .navbar .logo {
    width: 159px;
  }
  .item-post img,
  .item-post .skeleton-image {
    height: 300px;
  }
  footer .img-footer-2 {
    width: 100px;
  }
  footer .img-footer-1 {
    width: 100px;
  }
  footer .nav-list .item-nav {
    width: 100%;
    margin-bottom: 20px;
  }
  footer .logo-footer {
    width: 190px;
  }
  footer .copyright {
    width: 100%;
  }
  .section-page .title-section {
    font-size: 30px;
  }
  .section-page .ornament {
    width: 150px;
  }
  #post-page .img-blog {
    width: 100%;
  }
  #post-page .title-blog {
    font-size: 30px;
  }
  .react-multiple-carousel__arrow::before {
    font-size: 16px;
  }
  .react-multiple-carousel__arrow {
    min-height: 30px;
    min-width: 30px;
    bottom: 55%;
  }
  .item-product .review {
    font-size: 12px;
  }
}
#detail-product .accordion {
  border: unset;
}
#detail-product .accordion .accordion__item {
  border-top: 1px solid rgba(209, 191, 191, 0.631372549);
}
#detail-product .accordion .accordion__item .accordion__button:before {
  margin-top: 6px;
  margin-right: 0px;
}

.container-maps-contact {
  height: 65vh;
}

@media only screen and (min-width: 769px) and (max-width: 1024px) and (orientation: portrait) {
  /* For portrait layouts only */
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-toggler {
    display: none;
  }
  footer .img-footer-2,
  footer .img-footer-1 {
    width: 215px;
  }
  .section-page .img-right {
    height: 190px;
  }
  .container-maps-contact {
    height: 47vh;
  }
  .item-post img,
  .item-post .skeleton-image {
    height: 240px;
  }
}/*# sourceMappingURL=main.css.map */