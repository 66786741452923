section {
  padding-top: 70px;
}

#hero {
  position: relative;
  .bg-hero {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
  }
  .title {
    width: 40%;
    position: absolute;
    left: 5%;
    color: #fff;
    font-size: 80px;
    bottom: 5%;
    text-transform: uppercase;
    text-align: left;
    line-height: 104px;
    font-weight: bold;
  }

  .img-banner {
    width: 350px;
    position: absolute;
    bottom: 5%;
    right: 6%;
  }
  .logo {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 25%;
    width: 350px;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
  }
}

#aboutUs {
  .title {
    font-size: 48px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .sub-title {
    font-size: 64px;
    font-family: "Naigheta", sans-serif !important;
    color: var(--main-color);
  }

  .desc {
    color: var(--grey-color);
  }

  .img-about {
    width: 300px;
  }
}

#category {
  .container-with-dots {
    justify-content: center;
  }
  .item-category {
    position: relative;
    height: 300px;
    margin: 0px 20px;
    overflow: hidden;
    &:hover {
      img {
        transform: scale(1.2);
        -webkit-transform: scale(1.2);
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: -webkit-transform 0.5s ease;
      transition: transform 0.5s ease;
      transition: transform 0.5s ease, -webkit-transform 0.5s ease;
      -webkit-transition: transform 0.5s ease, -webkit-transform 0.5s ease;
      -moz-transition: transform 0.5s ease, -webkit-transform 0.5s ease;
      -ms-transition: transform 0.5s ease, -webkit-transform 0.5s ease;
      -o-transition: transform 0.5s ease, -webkit-transform 0.5s ease;
    }
    .title-item-category {
      background: rgba(3, 75, 43, 0.71);
      backdrop-filter: blur(5.400000095367432px);
      color: #fff;
      padding: 12px 7px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -20%);
      -webkit-transform: translate(-50%, -20%);
      -moz-transform: translate(-50%, -20%);
      -ms-transform: translate(-50%, -20%);
      -o-transform: translate(-50%, -20%);
      width: 170px;
      text-align: center;
      // display: -webkit-box;
      // -webkit-line-clamp: 1;
      // -webkit-box-orient: vertical;
      // overflow: hidden;
    }
  }
}

#banner {
  margin-top: 100px;
  margin-bottom: 100px;
  .img-banner {
    width: 100%;
  }
}

.maps {
  position: relative;
  margin-top: 100px;
  .info {
    position: absolute;
    z-index: 90;
    width: 339px;
    background-color: #fff;
    text-align: left;
    left: 3%;
    bottom: 5%;
    padding: 20px;

    .title {
      font-size: 30px;
      color: var(--main-color);
      font-weight: 600;
    }
    .address {
      color: var(--grey-color);
      font-size: 16px;
      margin-top: 15px;
    }
  }
}

@media (max-width: 768px) {
  #bestSelling {
    .carousel-best-selling {
      margin-top: 40px;
    }
  }
  section {
    padding-top: 50px;
  }
  #category .item-category {
    height: 200px;
    padding: 0 10px;
  }
  .container {
    padding: 0px 30px;
  }
  #aboutUs {
    .title {
      font-size: 35px;
    }
    .sub-title {
      font-size: 30px;
    }
    .img-about {
      width: 200px;
      margin-top: 50px !important;
    }
  }
  #banner {
    margin: 0px;
  }
  #hero {
    .img-banner {
      width: 50%;
      bottom: 25%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
    }
    .title {
      font-size: 30px;
      line-height: normal;
      text-align: center;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
    }
    .logo {
      width: 260px;
    }
  }

  .maps .info {
    width: 214px;
  }
  .maps .info .address {
    font-size: 14px;
  }
  .maps .info .title {
    font-size: 20px;
  }
  .maps {
    .info {
      .btn-more {
        font-size: 14px;
      }
    }
  }
}



@media only screen and (min-width: 769px) and (max-width: 1024px)  and (orientation:portrait){
  #hero {
    .img-banner {
      width: 40%;
      bottom: 16%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
    }
    .title {
      font-size: 30px;
      line-height: normal;
      text-align: center;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
    }
    .logo {
      width: 260px;
    }
  }

  #aboutUs {
    .title {
      font-size: 35px;
    }
    .sub-title {
      font-size: 30px;
    }
    .img-about {
      width: 200px;
      margin-top: 50px !important;
    }
  }

  .maps .info {
    width: 214px;
  }
  .maps .info .address {
    font-size: 14px;
  }
  .maps .info .title {
    font-size: 20px;
  }
  .maps {
    .info {
      .btn-more {
        font-size: 14px;
      }
    }
  }
}


@media only screen and (min-width: 769px) and (max-height: 820px)  and (orientation:landscape){
  #hero { 
    .title {
      font-size: 5cap;
      line-height: normal;
    }
  }
  
}


.h-65 {
  height: 65vh;
}