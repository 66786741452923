#list-product {
  padding-top: 150px;
}
#list-product .item-product {
  margin: 0px;
  margin-bottom: 30px;
}
#list-product .item-product .card-img {
  width: 100%;
}

.list-filter-category .item-filter {
  text-align: left;
  margin-bottom: 20px;
  font-size: 16px;
  color: var(--grey-color);
  cursor: pointer;
}
.list-filter-category .item-filter.active {
  color: var(--main-color);
  font-weight: 600;
}

#detail-product .title-product {
  font-size: 36px;
}
#detail-product .price-product {
  font-size: 24px;
}
#detail-product .price-product .original {
  font-size: 13px;
  text-decoration: line-through;
  display: inline-block;
  margin-right: 8px;
}
#detail-product .price-product .discount {
  color: #fc3434;
  font-weight: 600;
}
#detail-product .detail-title {
  font-size: 16px;
  font-weight: 600;
  font-family: "General Sans";
}
#detail-product .varian-item {
  border: 1px solid #e0e0e0;
  padding: 12px 10px;
  text-align: center;
  cursor: pointer;
}
#detail-product .varian-item.active, #detail-product .varian-item:hover {
  border-color: var(--main-color);
  background-color: var(--main-color);
  color: white;
}
#detail-product .review {
  color: var(--grey-color);
  font-size: 18px;
  margin-right: 20px;
}
#detail-product .share {
  color: var(--grey-color);
  font-size: 18px;
}
#detail-product .item-product .card-img {
  width: 100%;
}
#detail-product .main-img {
  width: 100%;
  height: 600px;
  -o-object-fit: cover;
     object-fit: cover;
}
#detail-product .item-img-catalog {
  width: 100%;
  margin-bottom: 20px;
  height: 150px;
  -o-object-fit: cover;
     object-fit: cover;
}
#detail-product .wrapper-catalog {
  overflow-y: hidden;
  height: 600px;
}
#detail-product figure.zoom {
  background-position: 50% 50%;
  position: relative;
  height: 600px;
  overflow: hidden;
  cursor: zoom-in;
  background-repeat: no-repeat;
}
#detail-product figure.zoom img:hover {
  opacity: 0;
}
#detail-product figure.zoom img {
  transition: opacity 0.5s;
  display: block;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
#detail-product .wrapper-rating {
  min-width: 109px;
}
#detail-product .total-reviews {
  font-size: 16px;
  color: var(--grey);
}
#detail-product .star {
  width: 24px;
}
#detail-product .wrapper-review-bar {
  max-width: 500px;
  min-width: 400px;
  margin-left: 20px;
}
#detail-product .wrapper-review-bar .star {
  width: 15px;
  margin-right: 4px;
}
#detail-product .rating {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 3px;
}
#detail-product .review-bar {
  margin-bottom: 5px;
}
#detail-product .total-bar {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
}
#detail-product .wrapper-bar {
  background-color: #eceff1;
  height: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  width: 100%;
}
#detail-product .wrapper-bar .bar {
  background-color: var(--main-color);
  height: 100%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.list-reviewer {
  margin-top: 50px;
}
.list-reviewer .item-reviewer {
  border-bottom: 1px solid #e0e0e0;
  padding: 20px 0;
  margin-bottom: 0;
  font-family: "General Sans";
}
.list-reviewer .item-reviewer:first-child {
  border-top: 1px solid #e0e0e0;
}
.list-reviewer .item-reviewer .img-reviewer {
  width: 50px;
  height: 50px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.list-reviewer .item-reviewer .name {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}
.list-reviewer .item-reviewer .date {
  font-size: 400;
  color: var(--grey-color);
  margin-left: 10px;
}
.list-reviewer .item-reviewer .desc {
  font-size: 16px;
  color: var(--grey-color);
}

#detail-product, #list-product {
  padding-bottom: 100px;
}

@media (max-width: 768px) {
  #list-product .text {
    display: none;
  }
  #list-product .search {
    width: 200px;
    max-width: 200px;
  }
  #list-product {
    padding-top: 100px;
  }
  #detail-product .title-product {
    font-size: 30px;
  }
  #detail-product .share {
    margin-top: 15px;
    font-size: 15px;
  }
  #detail-product .wrapper-review-bar {
    max-width: 100%;
    min-width: 100%;
    margin-left: 0px;
  }
  #detail-product .wrapper-rating {
    min-width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }
  #detail-product .wrapper-catalog {
    height: unset;
  }
  #detail-product figure.zoom {
    height: 348px;
  }
  #detail-product .main-img {
    height: 348px;
  }
  #detail-product .item-img-catalog {
    padding-right: 15px;
    height: 125px;
  }
  #detail-product .price-product {
    width: 100%;
  }
  #detail-product .price-product .original {
    display: block;
  }
  #detail-product .review {
    font-size: 12px;
    margin-top: 15px;
    width: 50%;
    margin-right: unset;
  }
  #detail-product .item-product .review {
    width: unset;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1200px) {
  #detail-product .wrapper-catalog {
    height: unset;
  }
  #detail-product .item-img-catalog {
    padding-right: 15px;
  }
  #detail-product .price-product {
    margin-bottom: 20px;
    width: 100%;
  }
}/*# sourceMappingURL=list_product.css.map */